import React from "react";
import "../App.scss";

function NotFound() {
    document.body.classList.add('error-404');

    return (
        <figure className="error-404">
            <div className="sad-mac" />
            <figcaption>
                <span className="sr-text">Error 404: Not Found</span>
                <span className="e" />
                <span className="r" />
                <span className="r" />
                <span className="o" />
                <span className="r" />
                <span className="_4" />
                <span className="_0" />
                <span className="_4" />
                <span className="n" />
                <span className="o" />
                <span className="t" />
                <span className="f" />
                <span className="o" />
                <span className="u" />
                <span className="n" />
                <span className="d" />
            </figcaption>
        </figure>
    );
}

export default NotFound;
