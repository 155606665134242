import React from "react";
import Slider from "react-slick";
import Pagetitle from "../elements/Pagetitle";
import Testimonial from "../elements/Testimonial";

function shuffleArray(array) {
  let curId = array.length;
  while (0 !== curId) {
    let randId = Math.floor(Math.random() * curId);
    curId -= 1;
    let tmp = array[curId];
    array[curId] = array[randId];
    array[randId] = tmp;
  }
  return array.slice(0, 3);
}

const sliderData = [
  {
    id: 1,
    avatar: "images/testimonials/jorge.png",
    webp: "images/testimonials/jorge.webp",
    userName: "Jorge Montealegre",
    subtitle: "entrepreneur"
  },
  {
    id: 2,
    avatar: "images/testimonials/jesus.png",
    webp: "images/testimonials/jesus.webp",
    userName: "Jesús Sánchez",
    subtitle: "engineer"
  },
  {
    id: 3,
    avatar: "images/testimonials/joaquin.png",
    webp: "images/testimonials/joaquin.webp",
    userName: "Joaquín Pacheco",
    subtitle: "real_estate_agent"
  },
  {
    id: 4,
    avatar: "images/testimonials/elizabeth.png",
    webp: "images/testimonials/elizabeth.webp",
    userName: "Elizabeth Ruiz",
    subtitle: "psychologist"
  },
  {
    id: 5,
    avatar: "images/testimonials/isaac.png",
    webp: "images/testimonials/isaac.webp",
    userName: "Isaac Quevedo",
    subtitle: "pharmacist"
  },
  {
    id: 6,
    avatar: "images/testimonials/emiliano.png",
    webp: "images/testimonials/emiliano.webp",
    userName: "Emiliano Alegre",
    subtitle: "grocer"
  },
  {
    id: 7,
    avatar: "images/testimonials/silvia.png",
    webp: "images/testimonials/silvia.webp",
    userName: "Silvia Maestre",
    subtitle: "professor"
  },
  {
    id: 8,
    avatar: "images/testimonials/alexander.png",
    webp: "images/testimonials/alexander.webp",
    userName: "Alexander Millán",
    subtitle: "entrepreneur"
  },
  {
    id: 9,
    avatar: "images/testimonials/mauricio.png",
    webp: "images/testimonials/mauricio.webp",
    userName: "Mauricio Del Moral",
    subtitle: "dentist"
  },
  {
    id: 10,
    avatar: "images/testimonials/eduardo.png",
    webp: "images/testimonials/eduardo.webp",
    userName: "Eduardo Díaz",
    subtitle: "travel_agent"
  },
  {
    id: 11,
    avatar: "images/testimonials/juan.png",
    webp: "images/testimonials/juan.webp",
    userName: "Juan Carlos Jaramillo",
    subtitle: "librarian"
  },
  {
    id: 12,
    avatar: "images/testimonials/modesta.png",
    webp: "images/testimonials/modesta.webp",
    userName: "Modesta Serrano",
    subtitle: "real_estate_agent"
  },
  {
    id: 13,
    avatar: "images/testimonials/andrea.png",
    webp: "images/testimonials/andrea.webp",
    userName: "Andrea Toribio",
    subtitle: "interior_designer"
  },
  {
    id: 14,
    avatar: "images/testimonials/luisa.png",
    webp: "images/testimonials/luisa.webp",
    userName: "Ana Luisa San Miguel",
    subtitle: "wedding_planner"
  },
  {
    id: 15,
    avatar: "images/testimonials/ivan.png",
    webp: "images/testimonials/ivan.webp",
    userName: "Iván Castañeda",
    subtitle: "lawyer"
  }
];

function Testimonials() {
  const slidetSettings = {
    dots: true,
    arrows: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  return (
    <section id="testimonials">
      <div className="container">
        <Pagetitle title={window.dictionary.clients} />
        <Slider {...slidetSettings} className="testimonials-wrapper">
          {shuffleArray(sliderData).map((slider) => (
            <Testimonial key={slider.id} slider={slider} />
          ))}
        </Slider>
      </div>
    </section>
  );
}

export default Testimonials;
