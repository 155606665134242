import React from "react";
import Typed from "react-typed";
import { Link } from "react-scroll";
import { useScrollPercentage } from "react-scroll-percentage"

function Herosection(props) {
  const [ref, percentage] = useScrollPercentage({
    threshold: 0,
  });

  const header = document.getElementsByClassName("desktop-header-3")[0];

  if(typeof header !== "undefined") {
    let transparency = percentage.toPrecision(3);
    if(window.pageYOffset === 0) { transparency = 0; }
    header.style.backgroundColor = "rgba(255,255,255,"+ transparency +")";
  }

  return (
    <section
        id="home"
        className={
          props.light
              ? "home d-flex align-items-center light"
              : "home d-flex align-items-center"
        }
    >
      <div className="container" ref={ref}>
        <div className="intro">
          <picture className="mb-4">
            <source srcSet="images/me/1.webp" type="image/webp" />
            <source srcSet="images/me/1.png" type="image/png" />
            <img src="images/me/1.png" alt="Cristóbal Castillo" />
          </picture>
          <h1 className="mt-4 mb-2 mt-0">Cristóbal Castillo</h1>
          <p>
            {window.dictionary.im}{" "}
            <Typed
                strings={[
                  "",
                  window.dictionary.web_developer,
                  window.dictionary.app_developer,
                  window.dictionary.consultant,
                  window.dictionary.tester,
                  window.dictionary.blogger,
                  window.dictionary.freelancer
                ]}
                typeSpeed={80}
                backSpeed={40}
                attr="value"
                loop
            >
              <label value></label>
            </Typed>
          </p>

          <ul className="social-icons light list-inline mb-0 mt-4">
            <li className="list-inline-item">
              <a href={window.dictionary.whatsapp_link} rel="noopener noreferrer" title={window.dictionary.whatsapp} target="_blank">
                <i className="fab fa-whatsapp"></i>
                <p className="d-none">{window.dictionary.whatsapp}</p>
              </a>
            </li>
            <li className="list-inline-item">
              <a href="https://twitter.com/intent/user?screen_name=khristoff" rel="noopener noreferrer" title={window.dictionary.twitter} target="_blank">
                <i className="fab fa-twitter"></i>
                <p className="d-none">{window.dictionary.twitter}</p>
              </a>
            </li>
            <li className="list-inline-item">
              <a href="https://m.me/khrzysztof" rel="noopener noreferrer" title={window.dictionary.messenger} target="_blank">
                <i className="fab fa-facebook-messenger"></i>
                <p className="d-none">{window.dictionary.messenger}</p>
              </a>
            </li>
            <li className="list-inline-item">
              <a href="https://join.skype.com/invite/xtMcG5A7xuq5" rel="noopener noreferrer" title={window.dictionary.skype} target="_blank">
                <i className="fab fa-skype"></i>
                <p className="d-none">{window.dictionary.skype}</p>
              </a>
            </li>
            <li className="list-inline-item">
              <a href="https://linkedin.com/in/khristoff" rel="noopener noreferrer" title={window.dictionary.linkedin} target="_blank">
                <i className="fab fa-linkedin-in"></i>
                <p className="d-none">{window.dictionary.linkedin}</p>
              </a>
            </li>
            <li className="list-inline-item">
              <a href="https://github.com/khristoff" rel="noopener noreferrer" title={window.dictionary.github} target="_blank">
                <i className="fab fa-github"></i>
                <p className="d-none">{window.dictionary.github}</p>
              </a>
            </li>
          </ul>

          <div className="mt-4">
            <Link
                className="btn btn-default"
                to="section-contact"
                spy={true}
                smooth={true}
                duration={500}
            >
              {window.dictionary.hire_me}
            </Link>
          </div>
        </div>

        <div className="scroll-down">
          <Link
              to="section-about"
              spy={true}
              smooth={true}
              duration={500}
              className="mouse-wrapper"
          >
            <span>{window.dictionary.scrolldown}</span>
            <span className="mouse">
          <span className="wheel"></span>
        </span>
          </Link>
        </div>

      </div>
    </section>
  );
}

export default Herosection;
