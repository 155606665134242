import React from "react";
import TrackVisibility from "react-on-screen";
import Counter from "../elements/Counter";
import Pagetitle from "../elements/Pagetitle";
import Skill from "../elements/Skill";

function coffee() {
  let graduation = new Date("2008-07-04");
  let today = new Date();
  let days = today.getTime() - graduation.getTime();
  return Math.round(days/(1000*60*60*24));
}

const current  = new Date();
const websites = 113;
const apps     = 24;
const clients  = parseInt(websites) + parseInt(apps);
const years    = parseInt(current.getFullYear()) - parseInt(2008);

const aboutContent = {
  name: "khristoff",
  avatarImage: "/images/me/2.png",
  avatarWebp: "/images/me/2.webp",
  content: "me",
  end: "end",
  "years": years
};

const counterData = [
  {
    id: 1,
    title: "websites",
    count: websites,
    icon: "icon-screen-desktop",
  },
  {
    id: 2,
    title: "apps",
    count: apps,
    icon: "icon-screen-smartphone",
  },
  {
    id: 3,
    title: "coffee",
    count: coffee(),
    icon: "icon-cup",
  },
  {
    id: 4,
    title: "happy",
    count: clients,
    icon: "icon-emotsmile",
  },
];

function About() {
  return (
    <section id="about">
      <div className="container">
        <Pagetitle title={window.dictionary.about} />
        <div className="row">
          <div className="col-md-3">
            <div className="text-center text-md-left mw-150">
              <picture>
                <source srcSet={aboutContent.avatarWebp} type="image/webp" />
                <source srcSet={aboutContent.avatarImage} type="image/png" />
                <img src={aboutContent.avatarImage} alt={aboutContent.name} />
              </picture>
            </div>
            <div className="spacer d-md-none d-lg-none" data-height="30"></div>
          </div>

          <div className="col-md-9 triangle-left-md triangle-top-sm">
            <div className="rounded bg-white shadow-blue padding-30">
              <div className="row">
                <div className="col-12">
                  <p>{window.dictionary[aboutContent.content]} {aboutContent.years} {window.dictionary[aboutContent.end]}</p>
                  <div className="mt-3">
                    <a href="/downloads/resume.pdf" className="btn btn-default" rel="nofollow" title={window.dictionary.download} download>
                      {window.dictionary.download}
                    </a>
                  </div>
                  <div className="spacer d-md-none d-lg-none" data-height="30"></div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="spacer" data-height="70"></div>
        <div className="row fix-spacing">
          {counterData.map((counter) => (
            <div key={counter.id} className="col-md-3 col-sm-6">
              <TrackVisibility once>
                <Counter counterItem={counter} />
              </TrackVisibility>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
}

export default About;
