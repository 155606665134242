import React from "react";

function Testimonial({ slider }) {
  const { avatar, webp, userName, subtitle, id } = slider;
  return (
    <div className="testimonial-item text-center mx-auto">
      <div className="thumb mb-3 mx-auto mw-90">
        <picture>
          <source srcSet={webp} type="image/webp" />
          <source srcSet={avatar} type="image/png" />
          <img src={avatar} alt={userName} />
        </picture>
      </div>
      <h3 className="mt-3 mb-0">{userName}</h3>
      <span className="subtitle">{window.dictionary[subtitle]}</span>
      <div className="bg-white padding-30 shadow-blue rounded triangle-top position-relative mt-4">
        <p className="mb-0">{window.dictionary.testimonials[id]}</p>
      </div>
    </div>
  );
}

export default Testimonial;
