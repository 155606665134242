import React from "react";

const brandlogoData = [
  {
    id: 1,
    image: "images/tools/bootstrap.svg",
  },
  {
    id: 2,
    image: "images/tools/codeigniter.svg",
  },
  {
    id: 3,
    image: "images/tools/cordova.svg",
  },
  {
    id: 4,
    image: "images/tools/framework7.svg",
  },
  {
    id: 5,
    image: "images/tools/java.svg",
  },
  {
    id: 6,
    image: "images/tools/javascript.svg",
  },
  {
    id: 7,
    image: "images/tools/jquery.svg",
  },
  {
    id: 8,
    image: "images/tools/laravel.svg",
  },
  {
    id: 9,
    image: "images/tools/livewire.svg",
  },
  {
    id: 10,
    image: "images/tools/node.svg",
  },
  {
    id: 11,
    image: "images/tools/php.svg",
  },
  {
    id: 12,
    image: "images/tools/python.svg",
  },
  {
    id: 13,
    image: "images/tools/rails.svg",
  },
  {
    id: 14,
    image: "images/tools/react.svg",
  },
  {
    id: 15,
    image: "images/tools/sass.svg",
  },
  {
    id: 16,
    image: "images/tools/symfony.svg",
  },
  {
    id: 17,
    image: "images/tools/swift.svg",
  },
  {
    id: 18,
    image: "images/tools/tailwind.svg",
  },
  {
    id: 19,
    image: "images/tools/vue.svg",
  },
  {
    id: 20,
    image: "images/tools/zend.svg",
  },
];

function Brandlogos() {
  return (
    <div id="branglogos">
      <div className="container">
        <div className="row">
          {brandlogoData.map((brandlogo) => (
            <div className="col-md-3 col-6" key={brandlogo.id}>
              <div className="client-item">
                <div className="inner">
                  <img src={brandlogo.image} alt="client-name" />
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

export default Brandlogos;
