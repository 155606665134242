import React from "react";
import { BrowserRouter, Switch, Route } from "react-router-dom";
import Homepage3Light from "./pages/Homepage3Light";
import NotFound from "./pages/NotFound";
import Spanish from "./public/lang/es.json";
import English from "./public/lang/en.json";
import "./App.scss";

function App() {
  const language = () => {
      let language = 'en';
      navigator.languages.map(function(lang){
          if(lang.includes('es') && language === 'en') {
              language = 'es';
          }
      });
      return language;
  }

  window.dictionary = (language() != 'es') ? English : Spanish;

  if(language() != 'es') {
      document.title = window.dictionary.title;
  }

  return (
    <BrowserRouter>
      <Switch>
        <Route path="/" exact>
          <Homepage3Light />
        </Route>
        <Route path="" component={NotFound} />
      </Switch>
    </BrowserRouter>
  );
}

export default App;
