import React, { useState, useEffect } from "react";
import ScrollAnimation from "react-animate-on-scroll";
import Pagetitle from "../elements/Pagetitle";
import Portfolio from "../elements/Portfolio";

const filters = [
  {
    id: 1,
    text: "everything",
  },
  {
    id: 2,
    text: "websites",
  },
  {
    id: 3,
    text: "apps",
  }
];

const allData = [
  {
    id: 1,
    title: "Sideimss",
    description: "Sideimss es un sistema para la administración de nóminas y auditorías.",
    category: "websites",
    image: "images/portfolio/sideimss.jpg",
    webp: "images/portfolio/sideimss.webp",
    link: "https://sideimss.mx",
  },
  {
    id: 2,
    title: "Naufraghost",
    description: "Naufraghost es un blog de música, conciertos y festivales.",
    category: "websites",
    image: "images/portfolio/naufraghost.jpg",
    webp: "images/portfolio/naufraghost.webp",
    link: "https://naufraghost.com",
  },
  {
    id: 3,
    title: "Hacienda Cocoyoc",
    description: "La Hacienda Cocoyoc es un hermoso complejo arquitectónico construido en el siglo XVII.",
    category: "websites",
    image: "images/portfolio/hcocoyoc.jpg",
    webp: "images/portfolio/hcocoyoc.webp",
    link: "https://hcocoyoc.com/",
  },
  {
    id: 4,
    title: "Cleantap",
    description: "Empresa de tecnología dedicada al servicio de tintorería y lavandería a través de nuestra aplicación móvil.",
    category: "apps",
    image: "images/portfolio/cleantap.jpg",
    webp: "images/portfolio/cleantap.webp",
    link: "https://cleantap.com.mx",
  },
  {
    id: 5,
    title: "Smartgas",
    description: "Smartgas es la solución para tener siempre los niveles óptimos de Gas LP.",
    category: "apps",
    image: "images/portfolio/smartgas.jpg",
    webp: "images/portfolio/smartgas.webp",
    link: "https://apps.apple.com/mx/app/smartgas/id1363592739",
  },
  {
    id: 6,
    title: "Levo",
    description: "Solicita un servicio de lavado desde tu Smartphone y nuestros lavadores irán a donde se encuentre tu vehículo.",
    category: "apps",
    image: "images/portfolio/levo.jpg",
    webp: "images/portfolio/levo.webp",
    popupLink: ["images/portfolio/levo.jpg"],
  },
  {
    id: 7,
    title: "Leru Bienes Raíces",
    description: "Empresa para asesorarte en la compraventa, renta y administración de inmuebles en Xalapa, Veracruz.",
    category: "websites",
    image: "images/portfolio/leru.jpg",
    webp: "images/portfolio/leru.webp",
    link: "https://www.leru.com.mx/",
  },
  {
    id: 8,
    title: "Allidress",
    description: "Allidress es una tienda en línea construída a la medida del cliente.",
    category: "websites",
    image: "images/portfolio/allidress.jpg",
    webp: "images/portfolio/allidress.webp",
    popupLink: ["images/portfolio/allidress.jpg"],
  },
  {
    id: 9,
    title: "Smartrip",
    description: "Agencia de viajes de la ciudad de Puebla que ofrece paquetes para viajar con confianza y seguridad.",
    category: "websites",
    image: "images/portfolio/smartrip.jpg",
    webp: "images/portfolio/smartrip.webp",
    popupLink: ["images/portfolio/smartrip.jpg"],
  },
  {
    id: 10,
    title: "Electo",
    description: "Herramienta de innovación tecnológica en gestión, planificación y control de su campaña electoral.",
    category: "apps",
    image: "images/portfolio/electo.jpg",
    webp: "images/portfolio/electo.webp",
    popupLink: ["images/portfolio/electo.jpg"],
  },
  {
    id: 11,
    title: "Espejo fantástico",
    description: "Espejo Fantástico es lo más nuevo en renta de cabina fotográfica para eventos sociales, empresariales y activaciones o BTl.",
    category: "websites",
    image: "images/portfolio/espejofantastico.jpg",
    webp: "images/portfolio/espejofantastico.webp",
    link: "https://espejofantasticopuebla.com",
  },
  {
    id: 12,
    title: "Bueno Bienes Raíces",
    description: "Contacta a Bueno Bienes Raíces donde te ofrecemos la renta y venta de inmuebles, terrenos y locales en Córdoba, Veracruz.",
    category: "websites",
    image: "images/portfolio/bueno.jpg",
    webp: "images/portfolio/bueno.webp",
    link: "https://www.buenobienesraices.com.mx",
  },
  {
    id: 13,
    title: "Jetcab",
    description: "El Uber de los jets y aviones privados, verifica disponibilidad, reserva tu viaje y paga, todo desde la comodidad de tu teléfono.",
    category: "websites",
    image: "images/portfolio/jetcab.jpg",
    webp: "images/portfolio/jetcab.webp",
    popupLink: ["images/portfolio/jetcab.jpg"],
  },
  {
    id: 14,
    title: "Inmuebles.red",
    description: "Inmuebles.red es el indexador de propiedades más grande de todo el país, que te conecta con los mejores profesionales del ramo inmobiliario.",
    category: "websites",
    image: "images/portfolio/inmuebles.jpg",
    webp: "images/portfolio/inmuebles.webp",
    popupLink: ["images/portfolio/inmuebles.jpg"],
  },
  {
    id: 15,
    title: "Promotora Iprocoba",
    description: "Con más de 10 años de experiencia que ofrece el servicio de promoción, administración de inmuebles y asesoría en Querétaro.",
    image: "images/portfolio/iprocoba.jpg",
    category: "websites",
    webp: "images/portfolio/iprocoba.webp",
    link: "https://casaventaqueretaro.com.mx",
  },
  {
    id: 16,
    title: "Tu Casa en Morelos",
    description: "Expertos en administración y promoción Inmobiliaria en la cuidad de Cuernavaca, Morelos.",
    image: "images/portfolio/tucasaenmorelos.jpg",
    category: "websites",
    webp: "images/portfolio/tucasaenmorelos.webp",
    link: "https://casaventaqueretaro.com.mx",
  },
  {
    id: 17,
    title: "Motzorongo",
    description: "Blog de noticias dedicada a Motzorongo, Tezonapa, en el estado de Veracruz.",
    category: "websites",
    image: "images/portfolio/motzorongo.jpg",
    webp: "images/portfolio/motzorongo.webp",
    link: "https://motzorongo.com.mx/",
  },
  {
    id: 18,
    title: "SAE Inmobiliario",
    description: "La red social de profesionales inmobiliarios.",
    category: "websites",
    image: "images/portfolio/sae.jpg",
    webp: "images/portfolio/sae.webp",
    popupLink: ["images/portfolio/sae.jpg"],
  },
  {
    id: 19,
    title: "Privadas de Huilango",
    description: "Privadas de Huilango es una empresa mexicana con mucha experiencia en la venta de departamentos en Córdoba, Veracruz.",
    image: "images/portfolio/huilango.jpg",
    category: "websites",
    webp: "images/portfolio/huilango.webp",
    link: "http://privadasdehuilango.com.mx",
  },
  {
    id: 20,
    title: "Imperio Bienes Raíces",
    description: "Oficinas inmobiliarias en la región de Córdoba, Veracruz, que se distingue por su trato personalizado y profesional.",
    image: "images/portfolio/imperio.jpg",
    category: "websites",
    webp: "images/portfolio/imperio.webp",
    link: "http://imperiobienesraices.com",
  },
  {
    id: 21,
    title: "Águila informativa",
    description: "Agencia de noticias en la ciudad de Cuautla, Morelos.",
    category: "websites",
    image: "images/portfolio/aguila.jpg",
    webp: "images/portfolio/aguila.webp",
    popupLink: ["images/portfolio/aguila.jpg"],
  },
  {
    id: 22,
    title: "Tío Pepe",
    description: "Tienda en línea de esta casa de empeño ubicada en Cuernavaca, Morelos.",
    category: "websites",
    image: "images/portfolio/tiopepe.jpg",
    webp: "images/portfolio/tiopepe.webp",
    popupLink: ["images/portfolio/tiopepe.jpg"],
  },
  {
    id: 23,
    title: "Pepe Casas",
    description: "Sitio web de la campaña política del candidato a diputado Pepe Casas en Cuernavaca, Morelos.",
    category: "websites",
    image: "images/portfolio/pepecasas.jpg",
    webp: "images/portfolio/pepecasas.webp",
    popupLink: ["images/portfolio/pepecasas.jpg"],
  },
  {
    id: 24,
    title: "Imán",
    description: "IMAN App es la red social más grande de promociones, descuentos y cupones en Puebla y CDMX.",
    category: "apps",
    image: "images/portfolio/iman.jpg",
    webp: "images/portfolio/iman.webp",
    link: "https://apps.apple.com/mx/app/iman/id1189342557",
  },
  {
    id: 25,
    title: "Seiso",
    description: "Seiso es la nueva aplicación móvil que conecta a miles de “Cleaning technician” con personas que necesiten que les laven su auto.",
    category: "apps",
    image: "images/portfolio/seiso.jpg",
    webp: "images/portfolio/seiso.webp",
    link: "https://apps.apple.com/mx/app/seiso/id1295255590",
  },
  {
    id: 26,
    title: "Sushi Wa",
    description: "El Sushi que llega a todas partes de México.",
    category: "apps",
    image: "images/portfolio/sushi.jpg",
    webp: "images/portfolio/sushi.webp",
    link: "https://apps.apple.com/mx/app/sushi-wa/id1450566837",
  },
  {
    id: 27,
    title: "Jhobie",
    description: "La mejor opción para empresas en busca de un excelente equipo de trabajo y para postulantes que buscan empleo.",
    category: "apps",
    image: "images/portfolio/jhobie.jpg",
    webp: "images/portfolio/jhobie.webp",
    link: "https://apps.apple.com/mx/app/jhobie/id1292076930",
  },
  {
    id: 28,
    title: "Entu",
    description: "Aplicación móvil que conecta a miles de usuarios con artistas, músicos, show's y entretenimiento para fiestas y eventos en todo México.",
    category: "apps",
    image: "images/portfolio/entu.jpg",
    webp: "images/portfolio/entu.webp",
    popupLink: ["images/portfolio/entu.jpg"],
  },
  {
    id: 29,
    title: "Coffee code",
    description: "Café de altura a domicilio, sabor insuperable, excelente calidad y precio accesible.",
    category: "apps",
    image: "images/portfolio/coffeecode.jpg",
    webp: "images/portfolio/coffeecode.webp",
    link: "https://apps.apple.com/mx/app/code/id1396009831",
  },
  {
    id: 30,
    title: "Invercity",
    description: "Invierte en bienes inmuebles de la mayor plusvalía del mercado latino. Seguro, innovador y de por vida. Crezca su patrimonio ahora.",
    category: "websites",
    image: "images/portfolio/invercity.jpg",
    webp: "images/portfolio/invercity.webp",
    popupLink: ["images/portfolio/invercity.jpg"],
  },
  {
    id: 31,
    title: "Terra",
    description: "Firma inmobiliaria 100% mexicana, enfocada en ayudar a nuestros clientes a tomar la decisión más adecuada en sus operaciones inmobiliarias.",
    category: "websites",
    image: "images/portfolio/terra.jpg",
    webp: "images/portfolio/terra.webp",
    link: "https://bienesraicesterra.com",
  },
  {
    id: 32,
    title: "MIA The Apps World",
    description: "Incubadora de aplicaciones. El éxito en la palma de tu mano.",
    category: "websites",
    image: "images/portfolio/mia.jpg",
    webp: "images/portfolio/mia.webp",
    popupLink: ["images/portfolio/mia.jpg"],
  },
  {
    id: 33,
    title: "Fixa",
    description: "Página web personalizado para agencia inmobiliaria de Puebla, Puebla.",
    category: "websites",
    image: "images/portfolio/fixa.jpg",
    webp: "images/portfolio/fixa.webp",
    popupLink: ["images/portfolio/fixa.jpg"],
  },
  {
    id: 34,
    title: "Golden Home RG",
    description: "Página web personalizado para agencia inmobiliaria de Tijuana, Baja California.",
    category: "websites",
    image: "images/portfolio/golden.jpg",
    webp: "images/portfolio/golden.webp",
    popupLink: ["images/portfolio/golden.jpg"],
  },
  {
    id: 35,
    title: "Promoción BR",
    description: "Página web personalizado para agencia inmobiliaria de Cuernavaca, Morelos.",
    category: "websites",
    image: "images/portfolio/promocionbr.jpg",
    webp: "images/portfolio/promocionbr.webp",
    popupLink: ["images/portfolio/promocionbr.jpg"],
  },
  {
    id: 36,
    title: "Inmobiliaria lizmar",
    description: "Página web personalizado para agencia inmobiliaria de Boca del río, Veracruz.",
    category: "websites",
    image: "images/portfolio/lizmar.jpg",
    webp: "images/portfolio/lizmar.webp",
    popupLink: ["images/portfolio/lizmar.jpg"],
  },
  {
    id: 37,
    title: "Hogar Consorcio",
    description: "Desarrolladora de vivienda especializada en la construcción y comercialización en la zona centro de México.",
    image: "images/portfolio/hogar.jpg",
    category: "websites",
    webp: "images/portfolio/hogar.webp",
    link: "https://www.hogar.com.mx/",
  },
  {
    id: 38,
    title: "Frutos Bienes Raíces",
    description: "Página web personalizado para agencia inmobiliaria de Querétaro, Querétaro.",
    category: "websites",
    image: "images/portfolio/frutos.jpg",
    webp: "images/portfolio/frutos.webp",
    popupLink: ["images/portfolio/frutos.jpg"],
  },
  {
    id: 39,
    title: "Driver México",
    description: "Empresa mexicana en la que el usuario vivirá una experiencia increíble en el proceso de alquiler de autos.",
    category: "websites",
    image: "images/portfolio/driver.jpg",
    webp: "images/portfolio/driver.webp",
    popupLink: ["images/portfolio/driver.jpg"],
  },
  {
    id: 40,
    title: "Icasa Inmobiliaria",
    description: "Empresa con experiencia de 20 años en el giro inmobiliario en la ciudad de Veracruz.",
    image: "images/portfolio/icasa.jpg",
    category: "websites",
    webp: "images/portfolio/icasa.webp",
    link: "https://www.icasainmobiliaria.com.mx/",
  },
  {
    id: 41,
    title: "Casa De Gracia Veracruz",
    description: "Comunidad dedicada al servicio de Cristo, unida por una iglesia con el mismo propósito.",
    category: "websites",
    image: "images/portfolio/casadegracia.jpg",
    webp: "images/portfolio/casadegracia.webp",
    popupLink: ["images/portfolio/casadegracia.jpg"],
  },
  {
    id: 42,
    title: "Inmobiliaria Spacios",
    description: "Página web personalizado para agencia inmobiliaria de Boca del río, Veracruz.",
    category: "websites",
    image: "images/portfolio/spacios.jpg",
    webp: "images/portfolio/spacios.webp",
    popupLink: ["images/portfolio/spacios.jpg"],
  },
  {
    id: 43,
    title: "Liari Bienes Raíces",
    description: "Profesionistas entusiastas y emprendedores dedicados a satisfacer las expectativas inmobiliarias en Veracruz y Toluca.",
    image: "images/portfolio/liari.jpg",
    category: "websites",
    webp: "images/portfolio/liari.webp",
    link: "http://www.liari.com.mx/",
  },
  {
    id: 44,
    title: "Bienes Raíces Coatepec",
    description: "Empresa inmobiliaria desde 1998 en la zona de Veracruz, Xalapa, Coatepec, Xico, Teocelo y alrededores.",
    image: "images/portfolio/bienesraicescoatepec.jpg",
    category: "websites",
    webp: "images/portfolio/bienesraicescoatepec.webp",
    link: "https://bienesraicescoatepec.com/",
  },
  {
    id: 45,
    title: "El Pórtico",
    description: "Empresa especializada en la compra-venta de inmuebles y todos los servicios inmobiliarios en Tequisquiapan, Morelos.",
    image: "images/portfolio/elportico.jpg",
    category: "websites",
    webp: "images/portfolio/elportico.webp",
    link: "http://elportico.com.mx/",
  },
  {
    id: 46,
    title: "Pluvalía Arquitectura",
    description: "Página web personalizado para firma de arquitectos en Xalapa, Veracruz.",
    category: "websites",
    image: "images/portfolio/plusvalia.jpg",
    webp: "images/portfolio/plusvalia.webp",
    popupLink: ["images/portfolio/plusvalia.jpg"],
  },
  {
    id: 47,
    title: "Promoinmuebles",
    description: "Venta, renta o traspaso de casas, departamentos, terrenos, oficinas, locales, bodegas, edificios, fincas, ranchos y mucho más.",
    category: "websites",
    image: "images/portfolio/promoinmuebles.jpg",
    webp: "images/portfolio/promoinmuebles.webp",
    popupLink: ["images/portfolio/promoinmuebles.jpg"],
  }
];

function Works() {
  const [getAllItems] = useState(allData);
  const [dataVisibleCount, setDataVisibleCount] = useState(6);
  const [dataIncrement] = useState(3);
  const [activeFilter, setActiveFilter] = useState("");
  const [visibleItems, setVisibleItems] = useState([]);
  const [noMorePost, setNoMorePost] = useState(false);

  useEffect(() => {
    setActiveFilter(filters[0].text.toLowerCase());
    setVisibleItems(getAllItems.filter((item) => item.id <= dataVisibleCount));
  }, [dataVisibleCount, getAllItems]);

  const handleChange = (e) => {
    e.preventDefault();
    setActiveFilter(e.target.getAttribute("data-category").toLowerCase());
    let tempData;
    if (e.target.getAttribute("data-category").toLowerCase() === filters[0].text.toLowerCase()) {
      tempData = getAllItems.filter((data) => data.id <= dataVisibleCount);
    } else {
      tempData = getAllItems.filter(
        (data) =>
          data.category === e.target.getAttribute("data-category").toLowerCase() &&
          data.id <= dataVisibleCount
      );
    }
    setVisibleItems(tempData);
  };

  const handleLoadmore = (e) => {
    e.preventDefault();
    let tempCount = dataVisibleCount + dataIncrement;
    if (dataVisibleCount > getAllItems.length) {
      setNoMorePost(true);
    } else {
      setDataVisibleCount(tempCount);
      if (activeFilter === filters[0].text.toLowerCase()) {
        setVisibleItems(getAllItems.filter((data) => data.id <= tempCount));
      } else {
        setVisibleItems(
          getAllItems.filter(
            (data) => data.category === activeFilter && data.id <= tempCount
          )
        );
      }
    }
  };

  return (
    <section id="works">
      <div className="container">
        <Pagetitle title={window.dictionary.projects} />

        {/* Start Portfolio Items */}
        <div className="row portfolio-wrapper">
          {visibleItems.map((item) => (
            <div className="col-md-4 col-sm-6 grid-item" key={item.id}>
              <Portfolio portfolio={item} />
            </div>
          ))}
        </div>
        {/* End Portfolio Items */}

        <div className="load-more text-center mt-4">
          <button
            className="btn btn-default"
            onClick={handleLoadmore}
            disabled={noMorePost ? "disabled" : null}
          >
            {noMorePost ? (
              window.dictionary.empty
            ) : (
              <span>
                {window.dictionary.more}
              </span>
            )}
          </button>
        </div>
      </div>
    </section>
  );
}

export default Works;
