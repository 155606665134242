import React from "react";
import { Link } from "react-scroll";
import Pagetitle from "../elements/Pagetitle";
import Service from "../elements/Service";

const servicesData = [
  {
    id: 1,
    icon: "images/service-1.svg",
    title: "webdev",
    content: "webdev_description",
    color: "#F9D74C",
    contentColor: "dark",
  },
  {
    id: 2,
    icon: "images/service-2.svg",
    title: "uiux",
    content: "uiux_description",
    color: "#6C6CE5",
    contentColor: "light",
  },
  {
    id: 3,
    icon: "images/service-3.svg",
    title: "mobile_apps",
    content: "apps_description",
    color: "#F97B8B",
    contentColor: "light",
  },
  {
    id: 4,
    icon: "images/service-4.svg",
    title: "marketing",
    content: "marketing_description",
    color: "#D4578E",
    contentColor: "light",
  },
  {
    id: 5,
    icon: "images/service-5.svg",
    title: "design",
    content: "design_description",
    color: "#F28465",
    contentColor: "light",
  },
  {
    id: 6,
    icon: "images/service-6.svg",
    title: "hosting",
    content: "hosting_description",
    color: "#11DBCF",
    contentColor: "light",
  },
];

function Services() {
  return (
    <section id="services">
      <div className="container">
        <Pagetitle title={window.dictionary.services} />
        <div className="row fix-spacing">
          {servicesData.map((service) => (
            <div className="col-md-4 col-equal" key={service.id}>
              <Service service={service} />
            </div>
          ))}
        </div>
        <div className="mt-5 text-center">
          <p className="mb-0">
            {window.dictionary.do_you_have_a_project}{" "}
            <Link
              className="link pointer"
              to="section-contact"
              spy={true}
              smooth={true}
              duration={500}
            >
              {window.dictionary.click_here}
            </Link>{" "}
            {window.dictionary.to_contact_me} 👋🏻
          </p>
        </div>
      </div>
    </section>
  );
}

export default Services;
