import React, { useState } from "react";
import ScrollAnimation from "react-animate-on-scroll";
import Pagetitle from "../elements/Pagetitle";
import emailjs from "emailjs-com";

function Contact() {
  const [formdata, setFormdata] = useState({
    name: "",
    email: "",
    subject: "",
    message: "",
  });

  const [error, setError] = useState(false);
  const [message, setMessage] = useState("");

  const submitHandler = (event) => {
    event.preventDefault();
    if (!formdata.name) {
      setError(true);
      setMessage(window.dictionary.form.required.name);
    } else if (!formdata.email) {
      setError(true);
      setMessage(window.dictionary.form.required.email);
    } else if (!formdata.subject) {
      setError(true);
      setMessage(window.dictionary.form.required.subject);
    } else if (!formdata.message) {
      setError(true);
      setMessage(window.dictionary.form.required.message);
    } else {
      setError(false);

      var templateParams = {
        name: formdata.name,
        email: formdata.email,
        subject: formdata.subject,
        message: formdata.message
      };

      emailjs.init("user_rWnSEcW9IxCBoTRpRNy64");
      emailjs.send("service_b1utwfd", "template_yca3vms", templateParams)
        .then(function(response) {
          formdata.name    = "";
          formdata.email   = "";
          formdata.subject = "";
          formdata.message = "";
          setMessage(window.dictionary.form.sent);
        }, function(error) {
          setMessage(error.text);
        });
    }
  };

  const handleChange = (event) => {
    setFormdata({
      ...formdata,
      [event.currentTarget.name]: event.currentTarget.value,
    });
  };

  const handleAlerts = () => {
    if (error && message) {
      return <div className="alert alert-danger mt-4">{message}</div>;
    } else if (!error && message) {
      return <div className="alert alert-success mt-4">{message}</div>;
    } else {
      return null;
    }
  };

  return (
    <section id="contact">
      <div className="container">
        <Pagetitle title={window.dictionary.contact} />
        <div className="row mt-2">
          <div className="col-12">
            <p>{window.dictionary.help}</p>
            <p>{window.dictionary.slogan}</p>
          </div>
        </div>
        <div className="spacer" data-height="60"></div>
        <div className="row">
          <div className="col-md-4">
            <div className="contact-info">
              <ScrollAnimation
                animateIn="fadeInUp"
                animateOut="fadeInOut"
                animateOnce={true}
              >
                <h3>{window.dictionary.form.title}</h3>
              </ScrollAnimation>
              <ScrollAnimation
                animateIn="fadeInUp"
                animateOut="fadeInOut"
                animateOnce={true}
              >
                <p>
                  {window.dictionary.form.subtitle}{" "}
                  <a href="mailto:contacto@khristoff.com" className="link">{window.dictionary.form.link}</a> 👋🏻
                </p>
              </ScrollAnimation>
            </div>
          </div>

          <div className="col-md-8">
            <form
              id="contact-form"
              className="contact-form mt-6"
              onSubmit={submitHandler}
            >
              <div className="row">
                <div className="column col-md-6">
                  <div className="form-group">
                    <input
                      type="text"
                      className="form-control"
                      name="name"
                      id="InputName"
                      placeholder={window.dictionary.form.name}
                      onChange={handleChange}
                      value={formdata.name}
                    />
                  </div>
                </div>

                <div className="column col-md-6">
                  <div className="form-group">
                    <input
                      type="email"
                      className="form-control"
                      id="InputEmail"
                      name="email"
                      placeholder={window.dictionary.form.email}
                      onChange={handleChange}
                      value={formdata.email}
                    />
                  </div>
                </div>

                <div className="column col-md-12">
                  <div className="form-group">
                    <input
                      type="text"
                      className="form-control"
                      id="InputSubject"
                      name="subject"
                      placeholder={window.dictionary.form.subject}
                      onChange={handleChange}
                      value={formdata.subject}
                    />
                  </div>
                </div>

                <div className="column col-md-12">
                  <div className="form-group">
                    <textarea
                      name="message"
                      id="InputMessage"
                      className="form-control"
                      rows="5"
                      placeholder={window.dictionary.form.message}
                      onChange={handleChange}
                      value={formdata.message}
                    ></textarea>
                  </div>
                </div>
              </div>
              <button
                type="submit"
                name="submit"
                id="submit"
                value={window.dictionary.form.send}
                className="btn btn-default"
              >
                {window.dictionary.form.send}
              </button>
            </form>
            {handleAlerts()}
          </div>
        </div>
      </div>
    </section>
  );
}

export default Contact;
